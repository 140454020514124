import React from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import Moment from 'react-moment';

export interface DateControlsProps {
    className?: string;
    date: string;
    format?: string;
    onNextClick: () => void;
    onPrevClick: () => void;
}

const DateControls: React.FC<DateControlsProps> = ({
    format = 'dddd, MMMM Do YYYY',
    className,
    date,
    onNextClick,
    onPrevClick
}) => {
    const classNames = classnames(
        'bg-white',
        'border',
        'border-primary',
        'p-1',
        'rounded',
        'shadow',
        className
    );

    return (
        <div className={classNames}>
            <div className="align-items-center d-flex flex-row justify-content-between">
                <Button type="button" color="primary" onClick={onPrevClick}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <div className="fw-bold p-2 text-center text-primary">
                    <Moment format={format}>{date}</Moment>
                </div>
                <Button type="button" color="primary" onClick={onNextClick}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>
        </div>
    );
};

export default DateControls;
