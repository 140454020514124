import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label
} from 'reactstrap';

export type NutritionFormValues = {
    calories: number;
    carbohydrates: number;
    date: string;
    fat: number;
    fiber: number;
    protein: number;
};

export type NutritionFormHelpers = FormikHelpers<NutritionFormValues>;

export interface NutritionFormProps {
    initialValues: NutritionFormValues;
    onSubmit: (values: NutritionFormValues, helpers: NutritionFormHelpers) => void;
}

const NutritionForm: React.FC<NutritionFormProps> = ({ initialValues, onSubmit }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ errors, isSubmitting, touched, values, handleBlur, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="date">Date</Label>
                    <Input
                        id="date"
                        type="date"
                        name="date"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.date}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="carbohydrates">Carbohydrates</Label>
                    <InputGroup>
                        <Input
                            id="carbohydrates"
                            type="number"
                            name="carbohydrates"
                            disabled={isSubmitting}
                            invalid={!!errors.carbohydrates && touched.carbohydrates}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.carbohydrates}
                        />
                        <InputGroupText>g</InputGroupText>
                        <FormFeedback>{errors.carbohydrates}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="fat">Fat</Label>
                    <InputGroup>
                        <Input
                            id="fat"
                            type="number"
                            name="fat"
                            disabled={isSubmitting}
                            invalid={!!errors.fat && touched.fat}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fat}
                        />
                        <InputGroupText>g</InputGroupText>
                        <FormFeedback>{errors.fat}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="protein">Protein</Label>
                    <InputGroup>
                        <Input
                            id="protein"
                            type="number"
                            name="protein"
                            disabled={isSubmitting}
                            invalid={!!errors.protein && touched.protein}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.protein}
                        />
                        <InputGroupText>g</InputGroupText>
                        <FormFeedback>{errors.protein}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="fiber">Fiber</Label>
                    <InputGroup>
                        <Input
                            id="fiber"
                            type="number"
                            name="fiber"
                            disabled={isSubmitting}
                            invalid={!!errors.fiber && touched.fiber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fiber}
                        />
                        <InputGroupText>g</InputGroupText>
                        <FormFeedback>{errors.fiber}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="fiber">Calories</Label>
                    <InputGroup>
                        <Input
                            id="calories"
                            type="number"
                            name="calories"
                            disabled={isSubmitting}
                            invalid={!!errors.calories && touched.calories}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.calories}
                        />
                        <InputGroupText>kcal</InputGroupText>
                        <FormFeedback>{errors.calories}</FormFeedback>
                    </InputGroup>
                </FormGroup>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    Save
                </Button>
            </Form>
        )}
    </Formik>
);

export default NutritionForm;
