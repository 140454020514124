import React from 'react';
import {
    faAngleRight,
    faFireAlt,
    faPencilAlt,
    faWalking,
    faWeightScale
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    PageHeader,
    PageHeaderContent,
    PageHeaderTitle
} from '@typedbootstrap/admin-pro-components';
import type { NutritionFormHelpers, NutritionFormValues } from 'components/organisms';
import { DateControls, Nutrition, NutritionForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Link } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';

export interface NutritionTrackerPageProps {
    date: string;
    initialNutritionFormValues: NutritionFormValues;
    modal: boolean;
    nutrition?: {
        calories?: number | null;
        carbohydrates?: number | null;
        fat?: number | null;
        fiber?: number | null;
        protein?: number | null;
    };
    onNextClick: () => void;
    onNutritionFormSubmit: (values: NutritionFormValues, helpers: NutritionFormHelpers) => void;
    onPrevClick: () => void;
    toggle: () => void;
}

const NutritionTrackerPage: React.FC<NutritionTrackerPageProps> = ({
    date,
    initialNutritionFormValues,
    modal,
    nutrition,
    onNextClick,
    onNutritionFormSubmit,
    onPrevClick,
    toggle
}) => (
    <DefaultTemplate>
        <PageHeader bg="primary" className="pb-10" dark gradient>
            <PageHeaderContent>
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col className="mt-4" xs="auto">
                            <PageHeaderTitle>Nutrition Tracker</PageHeaderTitle>
                        </Col>
                        <Col className="mt-4" xs="12" xl="auto">
                            <DateControls
                                date={date}
                                onNextClick={onNextClick}
                                onPrevClick={onPrevClick}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageHeaderContent>
        </PageHeader>
        <Container className="mt-n10">
            <Card className="card-header-actions mb-4 user-select-none">
                <CardHeader tag="h5">
                    Nutrition Nutrition
                    <Button type="button" color="transparent-dark" onClick={toggle} size="sm">
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                </CardHeader>
                <CardBody>
                    <Nutrition {...nutrition} />
                </CardBody>
                <CardFooter
                    tag={Link}
                    className="d-flex align-items-center justify-content-between small text-body"
                    to="/nutrition">
                    <div>View Nutrition History</div>
                    <FontAwesomeIcon icon={faAngleRight} />
                </CardFooter>
            </Card>
            <Row>
                <Col lg="4" md="6">
                    <Card className="mb-4 user-select-none">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center flex-shrink-0 me-3">
                                    <div className="icon-stack icon-stack-lg bg-primary-soft text-primary me-3">
                                        <FontAwesomeIcon icon={faWalking} />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="mb-1">-</h3>
                                        <div className="fw-bold small text-primary text-uppercase">
                                            Steps
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4" md="6">
                    <Card className="mb-4 user-select-none">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center flex-shrink-0 me-3">
                                    <div className="icon-stack icon-stack-lg bg-primary-soft text-primary me-3">
                                        <FontAwesomeIcon icon={faWeightScale} />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="mb-1">-</h3>
                                        <div className="fw-bold small text-primary text-uppercase">
                                            Weight
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="4" md="6">
                    <Card className="mb-4 user-select-none">
                        <CardBody>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center flex-shrink-0 me-3">
                                    <div className="icon-stack icon-stack-lg bg-primary-soft text-primary me-3">
                                        <FontAwesomeIcon icon={faFireAlt} />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <h3 className="mb-1">-</h3>
                                        <div className="fw-bold small text-primary text-uppercase">
                                            Cardio
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Nutrition</ModalHeader>
            <ModalBody>
                <NutritionForm
                    initialValues={initialNutritionFormValues}
                    onSubmit={onNutritionFormSubmit}
                />
            </ModalBody>
        </Modal>
    </DefaultTemplate>
);

export default NutritionTrackerPage;
