import React, { useState } from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, ListGroupItem, ListGroupProps } from 'reactstrap';

export type WorkoutListItem = {
    id: string;
    name: string;
};

export interface WorkoutListProps extends ListGroupProps {
    items: Array<WorkoutListItem>;
    onItemClick: (item: WorkoutListItem) => void;
}

const WorkoutList: React.FC<WorkoutListProps> = ({ items, onItemClick, ...props }) => {
    const [selectedItem, setSelectedItem] = useState<WorkoutListItem | null>(null);

    const handleClick = (item: WorkoutListItem): void => {
        setSelectedItem(item);
        onItemClick(item);
    };

    return (
        <ListGroup {...props}>
            {items.map(item => {
                const isActive = selectedItem?.id === item.id;

                return (
                    <ListGroupItem
                        key={item.id}
                        active={isActive}
                        onClick={() => handleClick(item)}
                        action>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>{item.name}</div>
                            {isActive && <FontAwesomeIcon icon={faCaretRight} />}
                        </div>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
};

export default WorkoutList;
