import React, { useEffect, useMemo, useState } from 'react';
import type { NutritionFormHelpers, NutritionFormValues } from 'components/organisms';
import { NutritionTrackerPage } from 'components/pages';
import {
    useCreateNutritionMutation,
    useGetCurrentUserNutritionTrackerLazyQuery,
    useUpdateNutritionMutation
} from 'graphql/generated';
import { useAppDispatch, useAppSelector, useUser } from 'hooks';
import moment from 'moment';
import { dateActions } from 'store/dateSlice';

export interface NutritionTrackerProps {}

const NutritionTracker: React.FC<NutritionTrackerProps> = () => {
    const dispatch = useAppDispatch();

    const date = useAppSelector(state => state.date.date);

    const { id: userId } = useUser();

    const [getNutritionTracker, { data }] = useGetCurrentUserNutritionTrackerLazyQuery();

    const [createNutrition] = useCreateNutritionMutation();

    const [updateNutrition] = useUpdateNutritionMutation();

    const [modal, setModal] = useState(false);

    useEffect(() => {
        getNutritionTracker({ variables: { date } });
    }, [date, getNutritionTracker]);

    const initialNutritionFormValues: NutritionFormValues = useMemo(() => {
        if (data?.me?.nutrition) {
            const nutrition = data.me.nutrition[0];
            if (nutrition) {
                return {
                    calories: nutrition.calories || 0,
                    carbohydrates: nutrition.carbohydrates || 0,
                    date: moment(nutrition.date).format('YYYY-MM-DD'),
                    fat: nutrition.fat || 0,
                    fiber: nutrition.fiber || 0,
                    protein: nutrition.protein || 0
                };
            }
        }
        return {
            calories: 0,
            carbohydrates: 0,
            date: moment(date).format('YYYY-MM-DD'),
            fat: 0,
            fiber: 0,
            protein: 0
        };
    }, [data, date]);

    const nutrition = useMemo(() => {
        if (data?.me.nutrition) {
            const nutrition = data.me.nutrition[0];

            if (nutrition) {
                return {
                    calories: nutrition.calories,
                    carbohydrates: nutrition.carbohydrates,
                    fat: nutrition.fat,
                    fiber: nutrition.fiber,
                    protein: nutrition.protein
                };
            }
        }
    }, [data]);

    const onNextClick = (): void => {
        dispatch(dateActions.next());
    };

    const onNutritionFormSubmit = (
        { calories, carbohydrates, date, fat, fiber, protein }: NutritionFormValues,
        { resetForm }: NutritionFormHelpers
    ): void => {
        if (data?.me.nutrition) {
            const nutrition = data.me.nutrition[0];

            const nutritionId = nutrition?.id;

            if (nutritionId) {
                updateNutrition({
                    variables: { nutritionId, calories, carbohydrates, date, fat, fiber, protein },
                    onCompleted: () => {
                        setModal(false);
                        resetForm();
                    },
                    update: (cache, result) => {
                        cache.modify({
                            id: cache.identify({ __typename: 'User', id: userId }),
                            fields: {
                                nutrition: (existingRefs = []) => [
                                    ...existingRefs,
                                    result.data!.updateNutrition
                                ]
                            }
                        });
                    }
                });

                return;
            }
        }

        createNutrition({
            variables: { calories, carbohydrates, date, fat, fiber, protein, userId },
            onCompleted: () => {
                setModal(false);
                resetForm();
            },
            update: (cache, result) => {
                cache.modify({
                    id: cache.identify({ __typename: 'User', id: userId }),
                    fields: {
                        nutrition: (existingRefs = []) => [
                            ...existingRefs,
                            result.data!.createNutrition
                        ]
                    }
                });
            }
        });
    };

    const onPrevClick = (): void => {
        dispatch(dateActions.prev());
    };

    const toggle = (): void => setModal(!modal);

    return (
        <NutritionTrackerPage
            {...{
                date,
                initialNutritionFormValues,
                modal,
                nutrition,
                onNextClick,
                onNutritionFormSubmit,
                onPrevClick,
                toggle
            }}
        />
    );
};

export default NutritionTracker;
