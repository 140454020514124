import React from 'react';
import type { ChangePasswordFormHelpers, ChangePasswordFormValues } from 'components/organisms';
import { AccountSecurityPage } from 'components/pages';
import { useChangePasswordMutation } from 'graphql/generated';

export interface AccountSecurityProps {}

const AccountSecurity: React.FC<AccountSecurityProps> = () => {
    const [changePassword] = useChangePasswordMutation();

    const initialChangePasswordFormValues: ChangePasswordFormValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const onChangePasswordFormSubmit = (
        { currentPassword, newPassword }: ChangePasswordFormValues,
        { setSubmitting, resetForm }: ChangePasswordFormHelpers
    ): void => {
        changePassword({
            variables: { currentPassword, newPassword },
            onCompleted: () => {
                resetForm();
            },
            onError: () => {
                setSubmitting(false);
            }
        });
    };

    return (
        <AccountSecurityPage {...{ initialChangePasswordFormValues, onChangePasswordFormSubmit }} />
    );
};

export default AccountSecurity;
