import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

interface DateState {
    date: string;
}

const initialState: DateState = {
    date: moment().toISOString()
};

export const dateSlice = createSlice({
    name: 'Date',
    initialState,
    reducers: {
        prev: state => {
            state.date = moment(state.date).subtract(1, 'day').toISOString();
        },
        next: state => {
            state.date = moment(state.date).add(1, 'day').toISOString();
        }
    }
});

export const { actions: dateActions, reducer: dateReducer } = dateSlice;
