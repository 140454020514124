import { SidenavNavigation, SidenavNavigationItems } from '@typedbootstrap/admin-components';
import React from 'react';
import { Link } from 'react-router-dom';

export interface SidenavProps {}

const Sidenav: React.FC<SidenavProps> = () => {
    const items: SidenavNavigationItems = [
        {
            heading: 'Trackers'
        },
        {
            name: 'Training Tracker',
            tag: Link,
            to: '/training-tracker'
        },
        {
            name: 'Nutrition Tracker',
            tag: Link,
            to: '/nutrition-tracker'
        }
    ];

    return <SidenavNavigation items={items} dark />;
};

export default Sidenav;
