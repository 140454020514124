import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
    [_ in K]?: never;
};
export type Incremental<T> =
    | T
    | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    JSON: { input: any; output: any };
    JSONObject: { input: any; output: any };
};

export type Activity = {
    __typename?: 'Activity';
    created_at: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    subject: Subject;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export enum ActivityLevel {
    Light = 'light',
    Moderate = 'moderate',
    Sedentary = 'sedentary',
    Very = 'very'
}

export type CreateDashboardInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateExerciseInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateGoalInput = {
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateMeasurementInput = {
    arms_cm?: InputMaybe<Scalars['Float']['input']>;
    chest_cm?: InputMaybe<Scalars['Float']['input']>;
    date: Scalars['String']['input'];
    height_cm?: InputMaybe<Scalars['Float']['input']>;
    hips_cm?: InputMaybe<Scalars['Float']['input']>;
    neck_cm?: InputMaybe<Scalars['Float']['input']>;
    thighs_cm?: InputMaybe<Scalars['Float']['input']>;
    user_id: Scalars['ID']['input'];
    waist_cm?: InputMaybe<Scalars['Float']['input']>;
    weight_kg?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateNutritionInput = {
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateProgrammeInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    user_id: Scalars['ID']['input'];
};

export type CreateSetInput = {
    date: Scalars['String']['input'];
    exercise_id: Scalars['ID']['input'];
    programme_id?: InputMaybe<Scalars['ID']['input']>;
    reps: Scalars['Int']['input'];
    user_id: Scalars['ID']['input'];
    weight: Scalars['Float']['input'];
    workout_id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateSurveyInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateUserInput = {
    date_of_birth: Scalars['String']['input'];
    email: Scalars['String']['input'];
    first_name: Scalars['String']['input'];
    gender: Gender;
    last_name: Scalars['String']['input'];
};

export type CreateWidgetInput = {
    config: Scalars['String']['input'];
    dashboard_id: Scalars['ID']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
};

export type CreateWorkoutInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    exercises?: InputMaybe<Array<WorkoutExerciseInput>>;
    name: Scalars['String']['input'];
};

export type Dashboard = {
    __typename?: 'Dashboard';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    widgets?: Maybe<Array<Widget>>;
};

export type Exercise = {
    __typename?: 'Exercise';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    pivot?: Maybe<WorkoutExercisePivot>;
    sets?: Maybe<Array<Set>>;
    updated_at?: Maybe<Scalars['String']['output']>;
    workouts?: Maybe<Array<Workout>>;
};

export type ExerciseSetsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    programmeId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
    workoutId?: InputMaybe<Scalars['ID']['input']>;
};

export enum Gender {
    Female = 'female',
    Male = 'male'
}

export type Goal = {
    __typename?: 'Goal';
    calories: Scalars['Int']['output'];
    carbohydrates: Scalars['Int']['output'];
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    fat: Scalars['Int']['output'];
    fiber: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    protein: Scalars['Int']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export type LoginInput = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type Measurement = {
    __typename?: 'Measurement';
    arms_cm?: Maybe<Scalars['Float']['output']>;
    chest_cm?: Maybe<Scalars['Float']['output']>;
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    height_cm?: Maybe<Scalars['Float']['output']>;
    hips_cm?: Maybe<Scalars['Float']['output']>;
    id: Scalars['ID']['output'];
    neck_cm?: Maybe<Scalars['Float']['output']>;
    thighs_cm?: Maybe<Scalars['Float']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    waist_cm?: Maybe<Scalars['Float']['output']>;
    weight_kg?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    _empty?: Maybe<Scalars['String']['output']>;
    changePassword: User;
    createDashboard: Dashboard;
    createExercise: Exercise;
    createGoal: Goal;
    createMeasurement: Measurement;
    createNutrition: Nutrition;
    createProgramme: Programme;
    createSet: Set;
    createSurvey: Survey;
    createUser: User;
    createWidget: Widget;
    createWorkout: Workout;
    deleteDashboard: Scalars['ID']['output'];
    deleteExercise: Scalars['ID']['output'];
    deleteGoal: Scalars['ID']['output'];
    deleteMeasurement: Scalars['ID']['output'];
    deleteNutrition: Scalars['ID']['output'];
    deleteProgramme: Scalars['ID']['output'];
    deleteSet: Scalars['ID']['output'];
    deleteSurvey: Scalars['ID']['output'];
    deleteUser: Scalars['ID']['output'];
    deleteWidget: Scalars['ID']['output'];
    deleteWorkout: Scalars['ID']['output'];
    likeDashboard: Scalars['ID']['output'];
    likeExercise: Scalars['ID']['output'];
    likeWorkout: Scalars['ID']['output'];
    login: Token;
    updateDashboard: Dashboard;
    updateExercise: Exercise;
    updateGoal: Goal;
    updateMeasurement: Measurement;
    updateNutrition: Nutrition;
    updateProgramme: Programme;
    updateSet: Set;
    updateSurvey: Survey;
    updateUser: User;
    updateWidget: Widget;
    updateWorkout: Workout;
};

export type MutationChangePasswordArgs = {
    currentPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
};

export type MutationCreateDashboardArgs = {
    input?: InputMaybe<CreateDashboardInput>;
};

export type MutationCreateExerciseArgs = {
    input?: InputMaybe<CreateExerciseInput>;
};

export type MutationCreateGoalArgs = {
    input?: InputMaybe<CreateGoalInput>;
};

export type MutationCreateMeasurementArgs = {
    input?: InputMaybe<CreateMeasurementInput>;
};

export type MutationCreateNutritionArgs = {
    input?: InputMaybe<CreateNutritionInput>;
};

export type MutationCreateProgrammeArgs = {
    input?: InputMaybe<CreateProgrammeInput>;
};

export type MutationCreateSetArgs = {
    input: CreateSetInput;
};

export type MutationCreateSurveyArgs = {
    input: CreateSurveyInput;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationCreateWidgetArgs = {
    input?: InputMaybe<CreateWidgetInput>;
};

export type MutationCreateWorkoutArgs = {
    input?: InputMaybe<CreateWorkoutInput>;
};

export type MutationDeleteDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type MutationDeleteExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type MutationDeleteGoalArgs = {
    goalId: Scalars['ID']['input'];
};

export type MutationDeleteMeasurementArgs = {
    measurementId: Scalars['ID']['input'];
};

export type MutationDeleteNutritionArgs = {
    nutritionId: Scalars['ID']['input'];
};

export type MutationDeleteProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type MutationDeleteSetArgs = {
    setId: Scalars['ID']['input'];
};

export type MutationDeleteSurveyArgs = {
    surveyId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
    userId: Scalars['ID']['input'];
};

export type MutationDeleteWidgetArgs = {
    widgetId: Scalars['ID']['input'];
};

export type MutationDeleteWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type MutationLikeDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type MutationLikeExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type MutationLikeWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
    input?: InputMaybe<LoginInput>;
};

export type MutationUpdateDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateDashboardInput>;
};

export type MutationUpdateExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateExerciseInput>;
};

export type MutationUpdateGoalArgs = {
    goalId: Scalars['ID']['input'];
    input?: InputMaybe<UpdateGoalInput>;
};

export type MutationUpdateMeasurementArgs = {
    input?: InputMaybe<UpdateMeasurementInput>;
    measurementId: Scalars['ID']['input'];
};

export type MutationUpdateNutritionArgs = {
    input?: InputMaybe<UpdateNutritionInput>;
    nutritionId: Scalars['ID']['input'];
};

export type MutationUpdateProgrammeArgs = {
    input?: InputMaybe<UpdateProgrammeInput>;
    programmeId: Scalars['ID']['input'];
};

export type MutationUpdateSetArgs = {
    input: UpdateSetInput;
    setId: Scalars['ID']['input'];
};

export type MutationUpdateSurveyArgs = {
    input: UpdateSurveyInput;
    surveyId: Scalars['ID']['input'];
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
    userId: Scalars['ID']['input'];
};

export type MutationUpdateWidgetArgs = {
    input?: InputMaybe<UpdateWidgetInput>;
    widgetId: Scalars['ID']['input'];
};

export type MutationUpdateWorkoutArgs = {
    input?: InputMaybe<UpdateWorkoutInput>;
    workoutId: Scalars['ID']['input'];
};

export type Nutrition = {
    __typename?: 'Nutrition';
    calories?: Maybe<Scalars['Int']['output']>;
    carbohydrates?: Maybe<Scalars['Int']['output']>;
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    fat?: Maybe<Scalars['Int']['output']>;
    fiber?: Maybe<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    protein?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
};

export type Permission = {
    __typename?: 'Permission';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Programme = {
    __typename?: 'Programme';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    workout?: Maybe<Workout>;
    workouts?: Maybe<Array<Workout>>;
};

export type ProgrammeWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type ProgrammeWorkoutInput = {
    workout_id: Scalars['ID']['input'];
};

export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']['output']>;
    activities?: Maybe<Array<Activity>>;
    bmr: Scalars['Int']['output'];
    dashboard: Dashboard;
    dashboards?: Maybe<Array<Dashboard>>;
    exercise: Exercise;
    exercises?: Maybe<Array<Exercise>>;
    goal: Goal;
    goals?: Maybe<Array<Goal>>;
    me: User;
    measurement: Measurement;
    measurements?: Maybe<Array<Measurement>>;
    nutrition: Nutrition;
    nutritions?: Maybe<Array<Nutrition>>;
    programme: Programme;
    programmes?: Maybe<Array<Programme>>;
    set: Set;
    sets?: Maybe<Array<Set>>;
    survey: Survey;
    surveys?: Maybe<Array<Survey>>;
    tdee: Scalars['Int']['output'];
    user: User;
    users?: Maybe<Array<User>>;
    widget: Widget;
    widgets?: Maybe<Array<Widget>>;
    workout: Workout;
    workouts?: Maybe<Array<Workout>>;
};

export type QueryActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBmrArgs = {
    age: Scalars['Int']['input'];
    gender: Gender;
    height_cm: Scalars['Float']['input'];
    weight_kg: Scalars['Float']['input'];
};

export type QueryDashboardArgs = {
    dashboardId: Scalars['ID']['input'];
};

export type QueryDashboardsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExerciseArgs = {
    exerciseId: Scalars['ID']['input'];
};

export type QueryExercisesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGoalArgs = {
    goalId: Scalars['ID']['input'];
};

export type QueryGoalsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    user?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMeasurementArgs = {
    measurementId: Scalars['ID']['input'];
};

export type QueryNutritionArgs = {
    nutritionId: Scalars['ID']['input'];
};

export type QueryProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type QueryProgrammesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySetArgs = {
    setId: Scalars['ID']['input'];
};

export type QuerySetsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    exerciseId?: InputMaybe<Scalars['ID']['input']>;
    programmeId?: InputMaybe<Scalars['ID']['input']>;
    userId?: InputMaybe<Scalars['ID']['input']>;
    workoutId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySurveyArgs = {
    surveyId: Scalars['ID']['input'];
};

export type QuerySurveysArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTdeeArgs = {
    activity_level: ActivityLevel;
    calories: Scalars['Int']['input'];
};

export type QueryUserArgs = {
    userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWidgetArgs = {
    widgetId: Scalars['ID']['input'];
};

export type QueryWorkoutArgs = {
    workoutId: Scalars['ID']['input'];
};

export type QueryWorkoutsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Question = {
    __typename?: 'Question';
    created_at: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    question: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Role = {
    __typename?: 'Role';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Set = {
    __typename?: 'Set';
    created_at: Scalars['String']['output'];
    date: Scalars['String']['output'];
    exercise: Exercise;
    id: Scalars['ID']['output'];
    programme?: Maybe<Programme>;
    reps?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
    user: User;
    weight?: Maybe<Scalars['Float']['output']>;
    workout?: Maybe<Workout>;
};

export type Subject = Set;

export type Survey = {
    __typename?: 'Survey';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    questions?: Maybe<Array<Question>>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Token = {
    __typename?: 'Token';
    token: Scalars['String']['output'];
};

export type UpdateDashboardInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExerciseInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoalInput = {
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
    user_id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateMeasurementInput = {
    arms_cm?: InputMaybe<Scalars['Float']['input']>;
    chest_cm?: InputMaybe<Scalars['Float']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    height_cm?: InputMaybe<Scalars['Float']['input']>;
    hips_cm?: InputMaybe<Scalars['Float']['input']>;
    neck_cm?: InputMaybe<Scalars['Float']['input']>;
    thighs_cm?: InputMaybe<Scalars['Float']['input']>;
    waist_cm?: InputMaybe<Scalars['Float']['input']>;
    weight_kg?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateNutritionInput = {
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProgrammeInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    user_id?: InputMaybe<Scalars['ID']['input']>;
    workouts?: InputMaybe<Array<ProgrammeWorkoutInput>>;
};

export type UpdateSetInput = {
    date?: InputMaybe<Scalars['String']['input']>;
    reps?: InputMaybe<Scalars['Int']['input']>;
    weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateSurveyInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
    date_of_birth?: InputMaybe<Scalars['String']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    first_name?: InputMaybe<Scalars['String']['input']>;
    gender?: InputMaybe<Gender>;
    last_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidgetInput = {
    config?: InputMaybe<Scalars['String']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkoutInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    exercises?: InputMaybe<Array<WorkoutExerciseInput>>;
    name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
    __typename?: 'User';
    activities?: Maybe<Array<Activity>>;
    avatar: Scalars['String']['output'];
    created_at: Scalars['String']['output'];
    date_of_birth: Scalars['String']['output'];
    email: Scalars['String']['output'];
    first_name: Scalars['String']['output'];
    gender: Gender;
    goal?: Maybe<Goal>;
    goals?: Maybe<Array<Goal>>;
    id: Scalars['ID']['output'];
    last_name: Scalars['String']['output'];
    measurements?: Maybe<Array<Measurement>>;
    nutrition?: Maybe<Array<Nutrition>>;
    permissions?: Maybe<Array<Permission>>;
    programme: Programme;
    programmes?: Maybe<Array<Programme>>;
    roles?: Maybe<Array<Role>>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserGoalsArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMeasurementsArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserNutritionArgs = {
    date?: InputMaybe<Scalars['String']['input']>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserProgrammeArgs = {
    programmeId: Scalars['ID']['input'];
};

export type UserProgrammesArgs = {
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Widget = {
    __typename?: 'Widget';
    config: Scalars['String']['output'];
    created_at: Scalars['String']['output'];
    dashboard: Dashboard;
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type Workout = {
    __typename?: 'Workout';
    created_at: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    exercises?: Maybe<Array<Exercise>>;
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    pivot?: Maybe<WorkoutExercisePivot>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type WorkoutExerciseInput = {
    exercise_id: Scalars['ID']['input'];
    max_reps?: InputMaybe<Scalars['Int']['input']>;
    max_sets?: InputMaybe<Scalars['Int']['input']>;
    min_reps?: InputMaybe<Scalars['Int']['input']>;
    min_sets?: InputMaybe<Scalars['Int']['input']>;
    rest?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkoutExercisePivot = {
    __typename?: 'WorkoutExercisePivot';
    created_at: Scalars['String']['output'];
    is_drop_set: Scalars['Boolean']['output'];
    is_giant_set: Scalars['Boolean']['output'];
    is_super_set: Scalars['Boolean']['output'];
    max_reps?: Maybe<Scalars['Int']['output']>;
    max_sets?: Maybe<Scalars['Int']['output']>;
    min_reps?: Maybe<Scalars['Int']['output']>;
    min_sets?: Maybe<Scalars['Int']['output']>;
    notes?: Maybe<Scalars['String']['output']>;
    rest?: Maybe<Scalars['Int']['output']>;
    updated_at?: Maybe<Scalars['String']['output']>;
};

export type WorkoutsFilters = {
    __typename?: 'WorkoutsFilters';
    name?: Maybe<Scalars['String']['output']>;
};

export type ChangePasswordMutationVariables = Exact<{
    currentPassword: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
    __typename?: 'Mutation';
    changePassword: { __typename?: 'User'; id: string };
};

export type CreateNutritionMutationVariables = Exact<{
    calories: Scalars['Int']['input'];
    carbohydrates: Scalars['Int']['input'];
    date: Scalars['String']['input'];
    fat: Scalars['Int']['input'];
    fiber: Scalars['Int']['input'];
    protein: Scalars['Int']['input'];
    userId: Scalars['ID']['input'];
}>;

export type CreateNutritionMutation = {
    __typename?: 'Mutation';
    createNutrition: {
        __typename?: 'Nutrition';
        id: string;
        calories?: number | null;
        carbohydrates?: number | null;
        date: string;
        fat?: number | null;
        fiber?: number | null;
        protein?: number | null;
    };
};

export type CreateSetMutationVariables = Exact<{
    date: Scalars['String']['input'];
    reps: Scalars['Int']['input'];
    weight: Scalars['Float']['input'];
    exerciseId: Scalars['ID']['input'];
    programmeId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
    workoutId: Scalars['ID']['input'];
}>;

export type CreateSetMutation = {
    __typename?: 'Mutation';
    createSet: {
        __typename?: 'Set';
        id: string;
        date: string;
        reps?: number | null;
        weight?: number | null;
    };
};

export type DeleteNutritionMutationVariables = Exact<{
    nutritionId: Scalars['ID']['input'];
}>;

export type DeleteNutritionMutation = { __typename?: 'Mutation'; deleteNutrition: string };

export type DeleteSetMutationVariables = Exact<{
    setId: Scalars['ID']['input'];
}>;

export type DeleteSetMutation = { __typename?: 'Mutation'; deleteSet: string };

export type LoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type LoginMutation = {
    __typename?: 'Mutation';
    login: { __typename?: 'Token'; token: string };
};

export type UpdateNutritionMutationVariables = Exact<{
    nutritionId: Scalars['ID']['input'];
    calories?: InputMaybe<Scalars['Int']['input']>;
    carbohydrates?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['String']['input']>;
    fat?: InputMaybe<Scalars['Int']['input']>;
    fiber?: InputMaybe<Scalars['Int']['input']>;
    protein?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpdateNutritionMutation = {
    __typename?: 'Mutation';
    updateNutrition: {
        __typename?: 'Nutrition';
        id: string;
        calories?: number | null;
        carbohydrates?: number | null;
        date: string;
        fat?: number | null;
        fiber?: number | null;
        protein?: number | null;
    };
};

export type GetCurrentUserQueryVariables = Exact<{
    includeNutrition?: InputMaybe<Scalars['Boolean']['input']>;
    includeProgrammes?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCurrentUserQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        first_name: string;
        last_name: string;
        nutrition?: Array<{
            __typename?: 'Nutrition';
            id: string;
            calories?: number | null;
            carbohydrates?: number | null;
            date: string;
            fat?: number | null;
            fiber?: number | null;
            protein?: number | null;
        }> | null;
        programmes?: Array<{ __typename?: 'Programme'; id: string; name: string }> | null;
    };
};

export type GetCurrentUserNutritionTrackerQueryVariables = Exact<{
    date?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCurrentUserNutritionTrackerQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        nutrition?: Array<{
            __typename?: 'Nutrition';
            id: string;
            calories?: number | null;
            carbohydrates?: number | null;
            date: string;
            fat?: number | null;
            fiber?: number | null;
            protein?: number | null;
        }> | null;
    };
};

export type GetProgrammeQueryVariables = Exact<{
    programmeId: Scalars['ID']['input'];
}>;

export type GetProgrammeQuery = {
    __typename?: 'Query';
    programme: {
        __typename?: 'Programme';
        id: string;
        name: string;
        workouts?: Array<{ __typename?: 'Workout'; id: string; name: string }> | null;
    };
};

export type GetWorkoutQueryVariables = Exact<{
    workoutId: Scalars['ID']['input'];
    programmeId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
    date: Scalars['String']['input'];
}>;

export type GetWorkoutQuery = {
    __typename?: 'Query';
    workout: {
        __typename?: 'Workout';
        id: string;
        name: string;
        exercises?: Array<{
            __typename?: 'Exercise';
            id: string;
            name: string;
            sets?: Array<{
                __typename?: 'Set';
                id: string;
                date: string;
                reps?: number | null;
                weight?: number | null;
            }> | null;
        }> | null;
    };
};

export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
        changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
            id
        }
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<
        ChangePasswordMutation,
        ChangePasswordMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
        ChangePasswordDocument,
        options
    );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
>;
export const CreateNutritionDocument = gql`
    mutation createNutrition(
        $calories: Int!
        $carbohydrates: Int!
        $date: String!
        $fat: Int!
        $fiber: Int!
        $protein: Int!
        $userId: ID!
    ) {
        createNutrition(
            input: {
                calories: $calories
                carbohydrates: $carbohydrates
                date: $date
                fat: $fat
                fiber: $fiber
                protein: $protein
                user_id: $userId
            }
        ) {
            id
            calories
            carbohydrates
            date
            fat
            fiber
            protein
        }
    }
`;
export type CreateNutritionMutationFn = Apollo.MutationFunction<
    CreateNutritionMutation,
    CreateNutritionMutationVariables
>;

/**
 * __useCreateNutritionMutation__
 *
 * To run a mutation, you first call `useCreateNutritionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNutritionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNutritionMutation, { data, loading, error }] = useCreateNutritionMutation({
 *   variables: {
 *      calories: // value for 'calories'
 *      carbohydrates: // value for 'carbohydrates'
 *      date: // value for 'date'
 *      fat: // value for 'fat'
 *      fiber: // value for 'fiber'
 *      protein: // value for 'protein'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateNutritionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateNutritionMutation,
        CreateNutritionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateNutritionMutation, CreateNutritionMutationVariables>(
        CreateNutritionDocument,
        options
    );
}
export type CreateNutritionMutationHookResult = ReturnType<typeof useCreateNutritionMutation>;
export type CreateNutritionMutationResult = Apollo.MutationResult<CreateNutritionMutation>;
export type CreateNutritionMutationOptions = Apollo.BaseMutationOptions<
    CreateNutritionMutation,
    CreateNutritionMutationVariables
>;
export const CreateSetDocument = gql`
    mutation createSet(
        $date: String!
        $reps: Int!
        $weight: Float!
        $exerciseId: ID!
        $programmeId: ID!
        $userId: ID!
        $workoutId: ID!
    ) {
        createSet(
            input: {
                date: $date
                reps: $reps
                weight: $weight
                exercise_id: $exerciseId
                programme_id: $programmeId
                user_id: $userId
                workout_id: $workoutId
            }
        ) {
            id
            date
            reps
            weight
        }
    }
`;
export type CreateSetMutationFn = Apollo.MutationFunction<
    CreateSetMutation,
    CreateSetMutationVariables
>;

/**
 * __useCreateSetMutation__
 *
 * To run a mutation, you first call `useCreateSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetMutation, { data, loading, error }] = useCreateSetMutation({
 *   variables: {
 *      date: // value for 'date'
 *      reps: // value for 'reps'
 *      weight: // value for 'weight'
 *      exerciseId: // value for 'exerciseId'
 *      programmeId: // value for 'programmeId'
 *      userId: // value for 'userId'
 *      workoutId: // value for 'workoutId'
 *   },
 * });
 */
export function useCreateSetMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateSetMutation, CreateSetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateSetMutation, CreateSetMutationVariables>(
        CreateSetDocument,
        options
    );
}
export type CreateSetMutationHookResult = ReturnType<typeof useCreateSetMutation>;
export type CreateSetMutationResult = Apollo.MutationResult<CreateSetMutation>;
export type CreateSetMutationOptions = Apollo.BaseMutationOptions<
    CreateSetMutation,
    CreateSetMutationVariables
>;
export const DeleteNutritionDocument = gql`
    mutation deleteNutrition($nutritionId: ID!) {
        deleteNutrition(nutritionId: $nutritionId)
    }
`;
export type DeleteNutritionMutationFn = Apollo.MutationFunction<
    DeleteNutritionMutation,
    DeleteNutritionMutationVariables
>;

/**
 * __useDeleteNutritionMutation__
 *
 * To run a mutation, you first call `useDeleteNutritionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNutritionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNutritionMutation, { data, loading, error }] = useDeleteNutritionMutation({
 *   variables: {
 *      nutritionId: // value for 'nutritionId'
 *   },
 * });
 */
export function useDeleteNutritionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteNutritionMutation,
        DeleteNutritionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteNutritionMutation, DeleteNutritionMutationVariables>(
        DeleteNutritionDocument,
        options
    );
}
export type DeleteNutritionMutationHookResult = ReturnType<typeof useDeleteNutritionMutation>;
export type DeleteNutritionMutationResult = Apollo.MutationResult<DeleteNutritionMutation>;
export type DeleteNutritionMutationOptions = Apollo.BaseMutationOptions<
    DeleteNutritionMutation,
    DeleteNutritionMutationVariables
>;
export const DeleteSetDocument = gql`
    mutation deleteSet($setId: ID!) {
        deleteSet(setId: $setId)
    }
`;
export type DeleteSetMutationFn = Apollo.MutationFunction<
    DeleteSetMutation,
    DeleteSetMutationVariables
>;

/**
 * __useDeleteSetMutation__
 *
 * To run a mutation, you first call `useDeleteSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSetMutation, { data, loading, error }] = useDeleteSetMutation({
 *   variables: {
 *      setId: // value for 'setId'
 *   },
 * });
 */
export function useDeleteSetMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteSetMutation, DeleteSetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteSetMutation, DeleteSetMutationVariables>(
        DeleteSetDocument,
        options
    );
}
export type DeleteSetMutationHookResult = ReturnType<typeof useDeleteSetMutation>;
export type DeleteSetMutationResult = Apollo.MutationResult<DeleteSetMutation>;
export type DeleteSetMutationOptions = Apollo.BaseMutationOptions<
    DeleteSetMutation,
    DeleteSetMutationVariables
>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password }) {
            token
        }
    }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
    baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
    LoginMutation,
    LoginMutationVariables
>;
export const UpdateNutritionDocument = gql`
    mutation updateNutrition(
        $nutritionId: ID!
        $calories: Int
        $carbohydrates: Int
        $date: String
        $fat: Int
        $fiber: Int
        $protein: Int
    ) {
        updateNutrition(
            nutritionId: $nutritionId
            input: {
                calories: $calories
                carbohydrates: $carbohydrates
                date: $date
                fat: $fat
                fiber: $fiber
                protein: $protein
            }
        ) {
            id
            calories
            carbohydrates
            date
            fat
            fiber
            protein
        }
    }
`;
export type UpdateNutritionMutationFn = Apollo.MutationFunction<
    UpdateNutritionMutation,
    UpdateNutritionMutationVariables
>;

/**
 * __useUpdateNutritionMutation__
 *
 * To run a mutation, you first call `useUpdateNutritionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNutritionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNutritionMutation, { data, loading, error }] = useUpdateNutritionMutation({
 *   variables: {
 *      nutritionId: // value for 'nutritionId'
 *      calories: // value for 'calories'
 *      carbohydrates: // value for 'carbohydrates'
 *      date: // value for 'date'
 *      fat: // value for 'fat'
 *      fiber: // value for 'fiber'
 *      protein: // value for 'protein'
 *   },
 * });
 */
export function useUpdateNutritionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateNutritionMutation,
        UpdateNutritionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateNutritionMutation, UpdateNutritionMutationVariables>(
        UpdateNutritionDocument,
        options
    );
}
export type UpdateNutritionMutationHookResult = ReturnType<typeof useUpdateNutritionMutation>;
export type UpdateNutritionMutationResult = Apollo.MutationResult<UpdateNutritionMutation>;
export type UpdateNutritionMutationOptions = Apollo.BaseMutationOptions<
    UpdateNutritionMutation,
    UpdateNutritionMutationVariables
>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser($includeNutrition: Boolean = false, $includeProgrammes: Boolean = false) {
        me {
            id
            first_name
            last_name
            nutrition @include(if: $includeNutrition) {
                id
                calories
                carbohydrates
                date
                fat
                fiber
                protein
            }
            programmes @include(if: $includeProgrammes) {
                id
                name
            }
        }
    }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      includeNutrition: // value for 'includeNutrition'
 *      includeProgrammes: // value for 'includeProgrammes'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
    baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export function useGetCurrentUserLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export function useGetCurrentUserSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
        GetCurrentUserDocument,
        options
    );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
>;
export const GetCurrentUserNutritionTrackerDocument = gql`
    query getCurrentUserNutritionTracker($date: String) {
        me {
            id
            nutrition(date: $date) {
                id
                calories
                carbohydrates
                date
                fat
                fiber
                protein
            }
        }
    }
`;

/**
 * __useGetCurrentUserNutritionTrackerQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserNutritionTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserNutritionTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserNutritionTrackerQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCurrentUserNutritionTrackerQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >(GetCurrentUserNutritionTrackerDocument, options);
}
export function useGetCurrentUserNutritionTrackerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >(GetCurrentUserNutritionTrackerDocument, options);
}
export function useGetCurrentUserNutritionTrackerSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<
        GetCurrentUserNutritionTrackerQuery,
        GetCurrentUserNutritionTrackerQueryVariables
    >(GetCurrentUserNutritionTrackerDocument, options);
}
export type GetCurrentUserNutritionTrackerQueryHookResult = ReturnType<
    typeof useGetCurrentUserNutritionTrackerQuery
>;
export type GetCurrentUserNutritionTrackerLazyQueryHookResult = ReturnType<
    typeof useGetCurrentUserNutritionTrackerLazyQuery
>;
export type GetCurrentUserNutritionTrackerSuspenseQueryHookResult = ReturnType<
    typeof useGetCurrentUserNutritionTrackerSuspenseQuery
>;
export type GetCurrentUserNutritionTrackerQueryResult = Apollo.QueryResult<
    GetCurrentUserNutritionTrackerQuery,
    GetCurrentUserNutritionTrackerQueryVariables
>;
export const GetProgrammeDocument = gql`
    query getProgramme($programmeId: ID!) {
        programme(programmeId: $programmeId) {
            id
            name
            workouts {
                id
                name
            }
        }
    }
`;

/**
 * __useGetProgrammeQuery__
 *
 * To run a query within a React component, call `useGetProgrammeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgrammeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgrammeQuery({
 *   variables: {
 *      programmeId: // value for 'programmeId'
 *   },
 * });
 */
export function useGetProgrammeQuery(
    baseOptions: Apollo.QueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables> &
        ({ variables: GetProgrammeQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export function useGetProgrammeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export function useGetProgrammeSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetProgrammeQuery, GetProgrammeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetProgrammeQuery, GetProgrammeQueryVariables>(
        GetProgrammeDocument,
        options
    );
}
export type GetProgrammeQueryHookResult = ReturnType<typeof useGetProgrammeQuery>;
export type GetProgrammeLazyQueryHookResult = ReturnType<typeof useGetProgrammeLazyQuery>;
export type GetProgrammeSuspenseQueryHookResult = ReturnType<typeof useGetProgrammeSuspenseQuery>;
export type GetProgrammeQueryResult = Apollo.QueryResult<
    GetProgrammeQuery,
    GetProgrammeQueryVariables
>;
export const GetWorkoutDocument = gql`
    query getWorkout($workoutId: ID!, $programmeId: ID!, $userId: ID!, $date: String!) {
        workout(workoutId: $workoutId) {
            id
            name
            exercises {
                id
                name
                sets(
                    programmeId: $programmeId
                    userId: $userId
                    workoutId: $workoutId
                    date: $date
                ) {
                    id
                    date
                    reps
                    weight
                }
            }
        }
    }
`;

/**
 * __useGetWorkoutQuery__
 *
 * To run a query within a React component, call `useGetWorkoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkoutQuery({
 *   variables: {
 *      workoutId: // value for 'workoutId'
 *      programmeId: // value for 'programmeId'
 *      userId: // value for 'userId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetWorkoutQuery(
    baseOptions: Apollo.QueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables> &
        ({ variables: GetWorkoutQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(GetWorkoutDocument, options);
}
export function useGetWorkoutLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(
        GetWorkoutDocument,
        options
    );
}
export function useGetWorkoutSuspenseQuery(
    baseOptions?: Apollo.SuspenseQueryHookOptions<GetWorkoutQuery, GetWorkoutQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSuspenseQuery<GetWorkoutQuery, GetWorkoutQueryVariables>(
        GetWorkoutDocument,
        options
    );
}
export type GetWorkoutQueryHookResult = ReturnType<typeof useGetWorkoutQuery>;
export type GetWorkoutLazyQueryHookResult = ReturnType<typeof useGetWorkoutLazyQuery>;
export type GetWorkoutSuspenseQueryHookResult = ReturnType<typeof useGetWorkoutSuspenseQuery>;
export type GetWorkoutQueryResult = Apollo.QueryResult<GetWorkoutQuery, GetWorkoutQueryVariables>;
