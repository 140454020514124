import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    PageHeader,
    PageHeaderContent,
    PageHeaderTitle
} from '@typedbootstrap/admin-pro-components';
import { DefaultTemplate } from 'components/templates';
import { Button, Card, CardBody, CardHeader, Container, Table } from 'reactstrap';

export interface NutritionPageProps {
    data: Array<{
        id: string;
        calories: number;
        carbohydrates: number;
        date: string;
        fat: number;
        fiber: number;
        protein: number;
    }>;
    onDeleteButtonClick: (nutritionId: string) => void;
}

const NutritionPage: React.FC<NutritionPageProps> = ({ data, onDeleteButtonClick }) => (
    <DefaultTemplate>
        <PageHeader bg="primary" className="pb-10" dark gradient>
            <PageHeaderContent>
                <Container>
                    <PageHeaderTitle>Nutrition</PageHeaderTitle>
                </Container>
            </PageHeaderContent>
        </PageHeader>
        <Container className="mt-n10">
            <Card className="mb-4">
                <CardHeader tag="h5">Nutrition History</CardHeader>
                <CardBody className="p-0">
                    <Table className="mb-0 text-center" responsive>
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Carbs</th>
                                <th scope="col">Fat</th>
                                <th scope="col">Fiber</th>
                                <th scope="col">Protein</th>
                                <th scope="col">Calories</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item => (
                                <tr key={item.id}>
                                    <td>{item.date}</td>
                                    <td>{item.carbohydrates}g</td>
                                    <td>{item.fat}g</td>
                                    <td>{item.fiber}g</td>
                                    <td>{item.protein}g</td>
                                    <td>{item.calories} kcal</td>
                                    <td>
                                        <Button
                                            type="button"
                                            color="danger"
                                            onClick={() => onDeleteButtonClick(item.id)}
                                            size="sm">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default NutritionPage;
