import React, { useEffect, useMemo, useState } from 'react';
import type { SetFormHelpers, SetFormValues } from 'components/organisms';
import { TrainingTrackerPage } from 'components/pages';
import type { Exercise, Programme, Workout } from 'graphql/generated';
import {
    useCreateSetMutation,
    useDeleteSetMutation,
    useGetCurrentUserQuery,
    useGetProgrammeLazyQuery,
    useGetWorkoutLazyQuery
} from 'graphql/generated';
import { useAppDispatch, useAppSelector, useUser } from 'hooks';
import moment from 'moment';
import { dateActions } from 'store/dateSlice';

export interface TrainingTrackerProps {}

const TrainingTracker: React.FC<TrainingTrackerProps> = () => {
    const dispatch = useAppDispatch();

    const date = useAppSelector(state => state.date.date);

    const { id: userId } = useUser();

    const [createSet] = useCreateSetMutation();

    const [deleteSet] = useDeleteSetMutation();

    const { data } = useGetCurrentUserQuery({ variables: { includeProgrammes: true } });

    const [getProgramme, { data: programmeData }] = useGetProgrammeLazyQuery();

    const [getWorkout, { data: workoutData }] = useGetWorkoutLazyQuery();

    const [modal, setModal] = useState(false);

    const [exerciseId, setExerciseId] = useState<string>();

    const [programmeId, setProgrammeId] = useState<string>();

    const [workoutId, setWorkoutId] = useState<string>();

    useEffect(() => {
        if (programmeId) {
            getProgramme({ variables: { programmeId } });
        }
    }, [programmeId, getProgramme]);

    useEffect(() => {
        if (programmeId && workoutId) {
            getWorkout({ variables: { date, programmeId, userId, workoutId } });
        }
    }, [date, programmeId, userId, workoutId, getWorkout]);

    const initialSetFormValues: SetFormValues = {
        date: moment(date).format('YYYY-MM-DD'),
        reps: 0,
        weight: 0
    };

    const exercises: Array<
        Pick<Exercise, 'id' | 'name'> & {
            sets: Array<{ id: string; date: string; reps: number; weight: number }>;
        }
    > = useMemo(() => {
        if (workoutData?.workout.exercises) {
            return workoutData.workout.exercises.map(exercise => ({
                id: exercise.id,
                name: exercise.name,
                sets:
                    exercise.sets?.map(set => ({
                        id: set.id,
                        date: set.date,
                        reps: set.reps || 0,
                        weight: set.weight || 0
                    })) || []
            }));
        }

        return [];
    }, [workoutData]);

    const programmes: Array<Pick<Programme, 'id' | 'name'>> = useMemo(() => {
        if (data?.me.programmes) {
            return data.me.programmes.map(programme => ({
                id: programme.id,
                name: programme.name
            }));
        }

        return [];
    }, [data]);

    const workouts: Array<Pick<Workout, 'id' | 'name'>> = useMemo(() => {
        if (programmeData?.programme.workouts) {
            return programmeData.programme.workouts.map(workout => ({
                id: workout.id,
                name: workout.name
            }));
        }

        return [];
    }, [programmeData]);

    const closeModal = () => setModal(false);

    const onCloneClick = (setId: string): void => {
        if (programmeId && workoutId) {
            const set = exercises
                .map(exercise => exercise.sets)
                .flat()
                .find(({ id }) => id === setId);

            const exerciseId = exercises.find(exercise =>
                exercise.sets.some(set => set.id === setId)
            )?.id;

            if (set && exerciseId) {
                createSet({
                    variables: {
                        date: moment(set.date).format('YYYY-MM-DD'),
                        reps: set.reps,
                        weight: set.weight,
                        exerciseId,
                        programmeId,
                        userId,
                        workoutId
                    },
                    update: cache => {
                        cache.modify({
                            id: cache.identify({ __typename: 'Exercise', id: exerciseId }),
                            fields: {
                                sets: (existingRefs = []) => [...existingRefs, set]
                            }
                        });
                    }
                });
            }
        }
    };

    const onDeleteClick = (setId: string): void => {
        deleteSet({
            variables: { setId },
            update: cache => {
                cache.modify({
                    id: cache.identify({ __typename: 'User', id: userId }),
                    fields: {
                        sets: (existingRefs = [], { readField }) => {
                            return existingRefs.filter((existingRef: any) => {
                                return readField('id', existingRef) !== setId;
                            });
                        }
                    }
                });

                cache.evict({ id: `Set:${setId}` });
            }
        });
    };

    const onExerciseClick = (exerciseId: string) => {
        setExerciseId(exerciseId);
        setModal(true);
    };

    const onNextClick = (): void => {
        dispatch(dateActions.next());
    };

    const onPrevClick = (): void => {
        dispatch(dateActions.prev());
    };

    const onProgrammeClick = (programmeId: string): void => {
        setProgrammeId(programmeId);
    };

    const onSetFormSubmit = (
        { date, reps, weight }: SetFormValues,
        { resetForm }: SetFormHelpers
    ): void => {
        if (exerciseId && programmeId && workoutId) {
            createSet({
                variables: {
                    date,
                    reps,
                    weight,
                    exerciseId,
                    programmeId,
                    userId,
                    workoutId
                },
                onCompleted: () => {
                    closeModal();
                    resetForm();
                },
                update: (cache, result) => {
                    cache.modify({
                        id: cache.identify({ __typename: 'Exercise', id: exerciseId }),
                        fields: {
                            sets: (existingRefs = []) => [...existingRefs, result.data!.createSet]
                        }
                    });
                }
            });
        }
    };

    const onWorkoutClick = (workoutId: string): void => {
        setWorkoutId(workoutId);
    };

    return (
        <TrainingTrackerPage
            {...{
                date,
                exercises,
                initialSetFormValues,
                modal,
                programmes,
                workouts,
                closeModal,
                onCloneClick,
                onDeleteClick,
                onExerciseClick,
                onNextClick,
                onPrevClick,
                onProgrammeClick,
                onSetFormSubmit,
                onWorkoutClick
            }}
        />
    );
};

export default TrainingTracker;
