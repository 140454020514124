import React, { useState } from 'react';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup, ListGroupItem, ListGroupProps } from 'reactstrap';

export type ProgrammeListItem = {
    id: string;
    name: string;
};

export interface ProgrammeListProps extends ListGroupProps {
    items: Array<ProgrammeListItem>;
    onItemClick: (item: ProgrammeListItem) => void;
}

const ProgrammeList: React.FC<ProgrammeListProps> = ({ items, onItemClick, ...props }) => {
    const [selectedItem, setSelectedItem] = useState<ProgrammeListItem | null>(null);

    const handleClick = (item: ProgrammeListItem): void => {
        setSelectedItem(item);
        onItemClick(item);
    };

    return (
        <ListGroup {...props}>
            {items.map(item => {
                const isActive = selectedItem?.id === item.id;

                return (
                    <ListGroupItem
                        key={item.id}
                        active={isActive}
                        onClick={() => handleClick(item)}
                        action>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>{item.name}</div>
                            {isActive && <FontAwesomeIcon icon={faCaretRight} />}
                        </div>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
};

export default ProgrammeList;
