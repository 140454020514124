import React, { useMemo } from 'react';
import { NutritionPage } from 'components/pages';
import { useDeleteNutritionMutation, useGetCurrentUserQuery } from 'graphql/generated';
import { useUser } from 'hooks';

export interface NutritionProps {}

const Nutrition: React.FC<NutritionProps> = () => {
    const { id: userId } = useUser();

    const { data } = useGetCurrentUserQuery({ variables: { includeNutrition: true } });

    const [deleteNutrition] = useDeleteNutritionMutation();

    const nutrition = useMemo(() => {
        if (!data) return [];

        return (
            data.me.nutrition?.map(item => ({
                id: item.id,
                date: item.date,
                carbohydrates: item.carbohydrates || 0,
                fat: item.fat || 0,
                fiber: item.fiber || 0,
                protein: item.protein || 0,
                calories: item.calories || 0
            })) ?? []
        );
    }, [data]);

    const onDeleteButtonClick = (nutritionId: string): void => {
        deleteNutrition({
            variables: { nutritionId },
            update: cache => {
                cache.modify({
                    id: cache.identify({ __typename: 'User', id: userId }),
                    fields: {
                        nutrition: (existingRefs = [], { readField }) => {
                            return existingRefs.filter((existingRef: any) => {
                                return readField('id', existingRef) !== nutritionId;
                            });
                        }
                    }
                });

                cache.evict({ id: `Nutrition:${nutritionId}` });
            }
        });
    };

    return <NutritionPage {...{ data: nutrition, onDeleteButtonClick }} />;
};

export default Nutrition;
