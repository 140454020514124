import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export type ChangePasswordFormValues = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export type ChangePasswordFormHelpers = FormikHelpers<ChangePasswordFormValues>;

export interface ChangePasswordFormProps {
    initialValues: ChangePasswordFormValues;
    onSubmit: (values: ChangePasswordFormValues, helpers: ChangePasswordFormHelpers) => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ initialValues, onSubmit }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ errors, isSubmitting, touched, values, handleBlur, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="currentPassword">Current Password</Label>
                    <Input
                        id="currentPassword"
                        type="password"
                        name="currentPassword"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.currentPassword}
                        invalid={!!errors.currentPassword && touched.currentPassword}
                    />
                    <FormFeedback>{errors.currentPassword}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="newPassword">New Password</Label>
                    <Input
                        id="newPassword"
                        type="password"
                        name="newPassword"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        invalid={!!errors.newPassword && touched.newPassword}
                    />
                    <FormFeedback>{errors.newPassword}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <Input
                        id="confirmPassword"
                        type="password"
                        name="confirmPassword"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        invalid={!!errors.confirmPassword && touched.confirmPassword}
                    />
                    <FormFeedback>{errors.confirmPassword}</FormFeedback>
                </FormGroup>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    Save
                </Button>
            </Form>
        )}
    </Formik>
);

export default ChangePasswordForm;
