import React from 'react';
import type { ChangePasswordFormHelpers, ChangePasswordFormValues } from 'components/organisms';
import { ChangePasswordForm } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

export interface AccountSecurityPageProps {
    initialChangePasswordFormValues: ChangePasswordFormValues;
    onChangePasswordFormSubmit: (
        values: ChangePasswordFormValues,
        helpers: ChangePasswordFormHelpers
    ) => void;
}

const AccountSecurityPage: React.FC<AccountSecurityPageProps> = props => (
    <DefaultTemplate>
        <Container className="px-4" fluid>
            <h1 className="my-4">Account - Security</h1>
            <Card className="mb-4">
                <CardHeader tag="h5">Change Password</CardHeader>
                <CardBody>
                    <ChangePasswordForm
                        initialValues={props.initialChangePasswordFormValues}
                        onSubmit={props.onChangePasswordFormSubmit}
                    />
                </CardBody>
            </Card>
        </Container>
    </DefaultTemplate>
);

export default AccountSecurityPage;
