import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

export type SetFormValues = {
    date: string;
    reps: number;
    weight: number;
};

export type SetFormHelpers = FormikHelpers<SetFormValues>;

export interface SetFormProps {
    initialValues: SetFormValues;
    onSubmit: (values: SetFormValues, helpers: SetFormHelpers) => void;
}

const SetForm: React.FC<SetFormProps> = ({ initialValues, onSubmit }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting, values, handleBlur, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="date">Date</Label>
                    <Input
                        id="date"
                        type="date"
                        name="date"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.date}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="weight">Weight</Label>
                    <InputGroup>
                        <Input
                            id="weight"
                            type="number"
                            name="weight"
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.weight}
                        />
                        <InputGroupText>kg</InputGroupText>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Label for="reps">Reps</Label>
                    <Input
                        id="reps"
                        type="number"
                        name="reps"
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.reps}
                    />
                </FormGroup>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                    Save
                </Button>
            </Form>
        )}
    </Formik>
);

export default SetForm;
