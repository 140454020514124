import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_API_DOMAIN } from 'utilities/constants/environment';

const httpLink = createHttpLink({ uri: GRAPHQL_API_DOMAIN });

const authLink = setContext(async (_, { headers }) => ({
    headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem('token')}`
    }
}));

const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {}
            }
        }
    }),
    link: authLink.concat(httpLink)
});

export default client;
