import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export interface NutritionProps {
    calories?: number | null;
    carbohydrates?: number | null;
    fat?: number | null;
    fiber?: number | null;
    protein?: number | null;
}

const Nutrition: React.FC<NutritionProps> = ({ calories, carbohydrates, fat, fiber, protein }) => (
    <Container>
        <Row className="justify-content-center">
            <Col className="align-self-center" xs="6" xl="2">
                <div className="text-center">
                    <div className="fw-bold mb-3 text-primary text-uppercase">Protein (g)</div>
                    <div>{protein ?? '-'}</div>
                </div>
            </Col>
            <Col className="align-self-center" xs="6" xl="2">
                <div className="text-center">
                    <div className="fw-bold mb-3 text-primary text-uppercase">Carbs (g)</div>
                    <div>{carbohydrates ?? '-'}</div>
                </div>
            </Col>
            <Col className="align-self-center" xs="6" xl="2">
                <div className="text-center">
                    <div className="fw-bold mb-3 text-primary text-uppercase">Fat (g)</div>
                    <div>{fat ?? '-'}</div>
                </div>
            </Col>
            <Col className="align-self-center" xs="6" xl="2">
                <div className="text-center">
                    <div className="fw-bold mb-3 text-primary text-uppercase">Fiber (g)</div>
                    <div>{fiber ?? '-'}</div>
                </div>
            </Col>
            <Col className="align-self-center" xs="6" xl="2">
                <div className="text-center">
                    <div className="fw-bold mb-3 text-primary text-uppercase">Calories (kcal)</div>
                    <div>{calories ?? '-'}</div>
                </div>
            </Col>
        </Row>
    </Container>
);

export default Nutrition;
