import React from 'react';
import { faClone, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Table, TableProps } from 'reactstrap';

export type SetTableItem = {
    id: string;
    reps: number;
    weight: number;
};

export interface SetTableProps extends TableProps {
    items: Array<SetTableItem>;
    onCloneClick: (setId: string) => void;
    onDeleteClick: (setId: string) => void;
}

const SetTable: React.FC<SetTableProps> = ({ items, onCloneClick, onDeleteClick, ...props }) => (
    <Table {...props}>
        <thead>
            <tr>
                <th scope="col">Set</th>
                <th scope="col">Weight</th>
                <th scope="col">Reps</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            {items.map(item => (
                <tr key={item.id}>
                    <th scope="row">#</th>
                    <td>{item.weight}kg</td>
                    <td>{item.reps}</td>
                    <td>
                        <ButtonGroup>
                            <Button
                                type="button"
                                color="primary"
                                onClick={() => onCloneClick(item.id)}
                                size="sm">
                                <FontAwesomeIcon icon={faClone} />
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={() => onDeleteClick(item.id)}
                                size="sm">
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </ButtonGroup>
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>
);

export default SetTable;
