import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAuth, useUser } from 'hooks';
import { Link } from 'react-router-dom';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    UncontrolledDropdown
} from 'reactstrap';
import { uiActions } from 'store/uiSlice';

export interface TopnavProps {}

const Topnav: React.FC<TopnavProps> = () => {
    const dispatch = useAppDispatch();

    const auth = useAuth();

    const user = useUser();

    const logout = (): void => {
        auth.logout(() => {});
    };

    const toggleSidenav = (): void => {
        dispatch(uiActions.toggleSetting('sidebarToggled'));
    };

    return (
        <Navbar className="topnav" color="primary" dark expand>
            <NavbarBrand tag={Link} to="/">
                App
            </NavbarBrand>
            <Button
                id="sidenav-toggle"
                className="order-1 order-lg-0 me-4 me-lg-0"
                color="link"
                onClick={toggleSidenav}
                size="sm">
                <FontAwesomeIcon icon={faBars} />
            </Button>
            <div className="d-none d-md-inline-block ms-auto me-0 me-md-3 my-2 my-md-0" />
            <Nav className="ms-auto ms-md-0 me-3 me-lg-4" navbar>
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        {user.firstName} {user.lastName}
                    </DropdownToggle>
                    <DropdownMenu end>
                        <DropdownItem header>Account</DropdownItem>
                        <DropdownItem tag={Link} to="/account/security">
                            Security
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={logout}>Logout</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Navbar>
    );
};

export default Topnav;
