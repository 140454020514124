import React from 'react';
import { AuthenticatedApp, UnauthenticatedApp } from 'layouts';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
    AccountSecurity,
    ForgotPassword,
    Login,
    NoMatch,
    Nutrition,
    NutritionTracker,
    Register,
    TrainingTracker
} from './routes';

function App() {
    return (
        <Routes>
            {/* Authenticated routes */}
            <Route element={<AuthenticatedApp />}>
                <Route path="/" element={<Navigate to="/training-tracker" replace />} />
                <Route path="/account/security" element={<AccountSecurity />} />
                <Route path="/nutrition" element={<Nutrition />} />
                <Route path="/nutrition-tracker" element={<NutritionTracker />} />
                <Route path="/training-tracker" element={<TrainingTracker />} />
            </Route>

            {/* Unauthenticated routes */}
            <Route element={<UnauthenticatedApp />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
            </Route>

            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
}

export default App;
