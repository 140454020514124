import React from 'react';
import {
    PageHeader,
    PageHeaderContent,
    PageHeaderTitle
} from '@typedbootstrap/admin-pro-components';
import type {
    ProgrammeListItem,
    SetFormHelpers,
    SetFormValues,
    WorkoutListItem
} from 'components/organisms';
import { DateControls, ProgrammeList, SetForm, SetTable, WorkoutList } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap';

export interface TrainingTrackerPageProps {
    date: string;
    exercises: Array<{
        id: string;
        name: string;
        sets: Array<{ id: string; reps: number; weight: number }>;
    }>;
    initialSetFormValues: SetFormValues;
    modal: boolean;
    programmes: Array<ProgrammeListItem>;
    workouts: Array<WorkoutListItem>;
    closeModal: () => void;
    onCloneClick: (setId: string) => void;
    onDeleteClick: (setId: string) => void;
    onExerciseClick: (exerciseId: string) => void;
    onNextClick: () => void;
    onPrevClick: () => void;
    onProgrammeClick: (programmeId: string) => void;
    onSetFormSubmit: (values: SetFormValues, helpers: SetFormHelpers) => void;
    onWorkoutClick: (workoutId: string) => void;
}

const TrainingTrackerPage: React.FC<TrainingTrackerPageProps> = ({
    date,
    exercises,
    initialSetFormValues,
    modal,
    programmes,
    workouts,
    closeModal,
    onCloneClick,
    onDeleteClick,
    onExerciseClick,
    onNextClick,
    onPrevClick,
    onProgrammeClick,
    onSetFormSubmit,
    onWorkoutClick
}) => (
    <DefaultTemplate>
        <PageHeader bg="primary" className="pb-10" dark gradient>
            <PageHeaderContent>
                <Container>
                    <Row className="align-items-center justify-content-between">
                        <Col className="mt-4" xs="auto">
                            <PageHeaderTitle>Training Tracker</PageHeaderTitle>
                        </Col>
                        <Col className="mt-4" xs="12" xl="auto">
                            <DateControls
                                date={date}
                                onNextClick={onNextClick}
                                onPrevClick={onPrevClick}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageHeaderContent>
        </PageHeader>
        <Container className="mt-n10">
            <Row>
                <Col className="order-2 order-xl-1" xs="6" xl="3">
                    <Card className="mb-4 user-select-none">
                        <CardHeader tag="h5">Programmes</CardHeader>
                        <ProgrammeList
                            items={programmes}
                            onItemClick={item => onProgrammeClick(item.id)}
                            flush
                        />
                    </Card>
                </Col>
                <Col className="order-3 order-xl-2" xs="6" xl="3">
                    <Card className="mb-4 user-select-none">
                        <CardHeader tag="h5">Workouts</CardHeader>
                        <WorkoutList
                            items={workouts}
                            onItemClick={item => onWorkoutClick(item.id)}
                            flush
                        />
                    </Card>
                </Col>
                <Col className="order-1 order-xl-3" xs="12" xl="6">
                    {exercises.map(exercise => (
                        <Card key={exercise.id} className="mb-4 user-select-none">
                            <CardHeader tag="h5">{exercise.name}</CardHeader>
                            <SetTable
                                className="mb-0"
                                items={exercise.sets}
                                onCloneClick={onCloneClick}
                                onDeleteClick={onDeleteClick}
                                borderless
                                striped
                            />
                            <CardFooter>
                                <Button
                                    color="primary"
                                    onClick={() => onExerciseClick(exercise.id)}
                                    block>
                                    Add Set
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
        <Modal isOpen={modal} toggle={closeModal} centered>
            <ModalHeader toggle={closeModal}>Add Set</ModalHeader>
            <ModalBody>
                <SetForm initialValues={initialSetFormValues} onSubmit={onSetFormSubmit} />
            </ModalBody>
        </Modal>
    </DefaultTemplate>
);

export default TrainingTrackerPage;
