import { configureStore } from '@reduxjs/toolkit';
import { dateReducer } from './dateSlice';
import { uiReducer } from './uiSlice';

const store = configureStore({
    reducer: {
        date: dateReducer,
        ui: uiReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
